@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

/* --- VARIABLES --- */
:root {
  --bg-color: #101010;
  --hl-color: #60D937;
  --text-color: #f3f3f3;
  --secondary-text-color: #9f9f9f;
  --grey: #9f9f9f76;
  --spacing: 20px;
  --sm-spacing: 20px;
}

/* --- ANIMATIONS --- */

@keyframes slideDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(1%);
    opacity: 1;
  }
}

/* --- RESETS --- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: var(--bg-color);
}

body {
  font-family: "Poppins", sans-serif;
}

/* --- GLOBAL STYLES --- */
::selection {
  background-color: var(--hl-color);
  color: var(--text-color);
}

.pageTitle {
  color: var(--text-color);
  font-size: 36px;
  border-bottom: 3px solid var(--hl-color);
  margin-bottom: var(--spacing);
}

.pageDescription {
  color: var(--grey);
  font-size: 14px;
}

.socialIcons .icon {
  color: var(--text-color);
}

.socialIcons .icon:hover {
  scale: 1.2;
  color: var(--hl-color);
}

.btn {
  color: #000;
  background-color: var(--hl-color);
  border: none;
  font-size: 14px;
  padding: 12px var(--sm-spacing);
  border-radius: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.btn:hover {
  background-color: #017100;
  color: #f9f9f9;
}

/* --- MOBILE STYLES --- */

/* --- HEADER ---  */
header {
  display: flex;
  justify-content: center;
  padding: var(--sm-spacing) var(--spacing);
}

.logo {
  height: 60px;
}

/* --- MOBILE STYLES --- */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
}

.aboutContainer {
  border-bottom: 1px solid var(--grey);
}

.contentContainer {
  padding: 10px;
}

.contentContainer h4 {
  color: var(--hl-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.contentContainer h5 {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 15px;
}

.contentDescription {
  color: var(--secondary-text-color);
}

.infoContainer {
  border-top: 1px solid var(--grey);
  margin-top: var(--sm-spacing);
  padding-top: var(--sm-spacing);
}

.info {
  padding-left: 0;
}

.infoContainer span {
  color: var(--text-color);
}

.infoContainer p {
  color: var(--secondary-text-color);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.infoContainer a {
  color: var(--hl-color);
}

.infoContainer a:hover {
  color: #017100;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--sm-spacing);
}

.downloadCV {
  margin-top: var(--sm-spacing);
  margin-bottom: var(--sm-spacing);
}


ul {
  list-style-type: none !important;
}

@media (min-width: 750px) {
  .about .socialIcons .icon {
    display: inline;
  }

  .about .socialIcons::before {
    background-color: var(--grey);
    height: 2px;
    width: var(--sm-spacing);
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 10px;
  }
}

@media (min-width: 300px) {
  .row {
    margin-left: 2px;
  }
}
